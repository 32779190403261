import React, { Component } from 'react';
import { Link, withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

import './styles.styl';

class Menu extends Component {
  componentDidMount() {
    // setup the animation
    this.animation = lottie.loadAnimation({
      container: this.animationNode,
      render: 'svg',
      loop: true,
      autoplay: false,
      path: withPrefix('/animations/logo-yellow.json'),
    });
  }

  componentWillUnmount() {
    document.querySelector('html').className = '';
  }

  componentWillReceiveProps({ menuIsOpen }) {
    if (menuIsOpen && this.animation) {
      document.querySelector('html').className = 'non-scrollable';
      this.animation.play();
    } else if (!menuIsOpen && this.animation) {
      document.querySelector('html').className = '';
      this.animation.goToAndStop(0);
    }
  }

  renderPagesLinks() {
    const { pageLinks, handleLogout } = this.props;

    return (
      <div className="navigation-wrapper">
        <ul>
          {pageLinks.map(({ href, label, key }) => (
            <li key={key} onClick={key === 'logout' ? handleLogout : null}>
              <Link to={href}>{label}</Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderSocialsLinks() {
    const { socialLinks } = this.props;

    return (
      <div className="socials">
        <ul>
          {socialLinks.map(({ className, href, key }) => (
            <li className={className} key={key}>
              <a href={href} target="_blank" rel="noopener noreferrer" />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderMentions() {
    const { mentions } = this.props;

    return (
      <div className="mentions">
        <ul>
          <li>{mentions[0]}</li>
          <li>.</li>
          <li>{mentions[1]}</li>
          <li>.</li>
          <li>{mentions[2]}</li>
          <li>.</li>
          <li>{mentions[3]}</li>
        </ul>
      </div>
    );
  }

  render() {
    const { menuIsOpen } = this.props;

    return (
      <div className={`menu ${menuIsOpen ? '' : 'hidden'}`}>
        {/* <div
          className="anim"
          ref={elem => {
            this.animationNode = elem;
          }}
        /> */}
        {this.renderPagesLinks()}
        {/*this.renderSocialsLinks()*/}
        {this.renderMentions()}
      </div>
    );
  }
}

Menu.propTypes = {
  menuIsOpen: PropTypes.bool.isRequired,
  mentions: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleLogout: PropTypes.func.isRequired,
  pageLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Menu;
