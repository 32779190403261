export const getFormattedAddresses = form => {
  const shipping = `${
    form.shipping.company_name ? `${form.shipping.company_name}\n` : ''
  }${form.shipping.first_name} ${form.shipping.last_name}\n${
    form.shipping.phone ? `${form.shipping.phone}` : ''
  }\n${form.shipping.line_1}${
    form.shipping.line_2 ? `\n${form.shipping.line_2}\n` : '\n'
  }${form.shipping.postcode} ${
    form.shipping.city
  }\n${form.shipping.country.toUpperCase()}`;

  const billing = form.isShippingSameAsBilling
    ? shipping
    : `${form.billing.company_name ? `${form.billing.company_name}\n` : ''}${
        form.billing.first_name
      } ${form.billing.last_name}\n${
        form.billing.phone ? `${form.billing.phone}` : ''
      }\n${form.billing.line_1}${
        form.billing.line_2 ? `\n${form.billing.line_2}\n` : '\n'
      }${form.billing.postcode} ${
        form.billing.city
      }\n${form.billing.country.toUpperCase()}`;

  return { shipping, billing };
};

export const getFormattedAddressesForProfile = form => {
  const home_address = `${form.shipping.line_1} ${form.shipping.line_2}, ${
    form.shipping.postcode
  } ${form.shipping.city}`;
  const billing = form.isShippingSameAsBilling
    ? home_address
    : `${form.billing.line1} ${form.billing.line2}, ${form.billing.postcode} ${
        form.billing.city
      }`;
  return { home_address, billing };
};
