import includes from 'lodash/includes';

import {
  RESET_PASSWORD_EP,
  RESERVATION_EP,
  FORGOT_PASSWORD_EP,
  LOGIN_EP,
  PRODUCT_EP,
  PROFILES_EP,
  CREATE_ACCOUNT_EP,
  ME_EP,
  TRANSACTIONS_EP,
  SHOP_ORDERS_EP,
} from '../constants/monimalzApiEndpoint';

import { formatProduct } from './monimalz';

const requestMonimalzApi = (endpoint, body = {}, method = 'POST', token = '') =>
  fetch(`${process.env.GATSBY_MONIMALZ_API_URL}${endpoint}`, {
    method,
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
      'Mnz-Token': token,
    },
    body: body ? JSON.stringify({ payload: body }) : null,
  });

export const tryTokenRequest = token => {
  const url = `${ME_EP}?token=${token}`;
  return requestMonimalzApi(url, null, 'GET', token);
};

/**
 * Re-initialize the user password
 * @param token { string } - The unique token of the user, get from the url
 * @param password { string } - The new password of the user
 * @param confirm { string } - The confirmation of the new password (both should match)
 * @returns { Promise }
 */
export const resetPasswordRequest = (token, password, confirm) => {
  // Build url
  const url = `${RESET_PASSWORD_EP}?token=${token}`;
  const body = {
    password,
    password_confirm: confirm,
  };
  return requestMonimalzApi(url, body);
};

/**
 * Re-initialize the user password
 * @param form Object containing the user datas
 * @returns { Promise }
 */
export const createAccountRequest = form => {
  // Build url
  const url = `${CREATE_ACCOUNT_EP}`;
  return requestMonimalzApi(url, form);
};

export const loginRequest = (email, password) => {
  const body = {
    email,
    password,
  };
  return requestMonimalzApi(LOGIN_EP, body).then(res => {
    if (res.status >= 300) throw res;
    return res.json();
  });
};

export const forgotPasswordRequest = email =>
  requestMonimalzApi(FORGOT_PASSWORD_EP, { email }, 'POST');

export const listProfiles = token => {
  return requestMonimalzApi(PROFILES_EP, null, 'GET', token);
};

export const createProfile = (token, profileData) => {
  const { firstname, lastname } = profileData;
  return requestMonimalzApi(
    PROFILES_EP,
    {
      nickname: `${firstname} ${lastname}`,
      type: 'PARENT',
      ...profileData,
    },
    'POST',
    token
  );
};

export const getTransaction = (token, transactionUuid) => {
  return requestMonimalzApi(
    `${TRANSACTIONS_EP}/${transactionUuid}`,
    null,
    'GET',
    token
  );
};

export const updateProfile = (token, profileData, profileUuid) => {
  return requestMonimalzApi(
    `${PROFILES_EP}/${profileUuid}`,
    profileData,
    'PUT',
    token
  );
};

export const createPaymentMeans = (profileUuid, stripeToken, mnzToken) => {
  return requestMonimalzApi(
    `v1/profiles/${profileUuid}/payment-means`,
    {
      stripe_token_id: stripeToken,
    },
    'POST',
    mnzToken
  );
};

export const deletePaymentMeans = (profileUuid, paymentmeansUuid, mnzToken) => {
  return requestMonimalzApi(
    `v1/profiles/${profileUuid}/payment-means/${paymentmeansUuid}`,
    null,
    'DELETE',
    mnzToken
  );
};

export const createOrder = (
  payment_means_uuid,
  profile_uuid,
  cart,
  shipping_address,
  billing_address,
  mnzToken
) => {
  return requestMonimalzApi(
    `v1/shop/orders`,
    {
      payment_means_uuid,
      profile_uuid,
      cart,
      shipping_address,
      billing_address,
    },
    'POST',
    mnzToken
  );
};

export const payOrder = (orderUuid, return_uri, mnzToken) => {
  return requestMonimalzApi(
    `v1/shop/orders/${orderUuid}/checkout`,
    {
      return_uri,
    },
    'POST',
    mnzToken
  );
};

export const fetchAllMonimalz = () => {
  return requestMonimalzApi(PRODUCT_EP, null, 'GET').then(res =>
    res.json().then(data =>
      data.reduce(
        (acc, curr) => {
          const myProduct = formatProduct(curr);
          acc.products[myProduct.uuid] = myProduct;
          if (includes(myProduct.categories, 'monimalz')) {
            acc.monimalzs.push({ type: 'product', id: myProduct.uuid });
          }
          if (includes(myProduct.categories, 'accessories')) {
            acc.accessories.push({ type: 'product', id: myProduct.uuid });
          }
          return acc;
        },
        {
          products: {},
          monimalzs: [],
          accessories: [],
        }
      )
    )
  );
};

export const apiStatusIsError = status => {
  if (status >= 400 && status <= 512) return true;
  return false;
};

export const fetchShopOrders = token =>
  requestMonimalzApi(SHOP_ORDERS_EP, null, 'GET', token)
    .then(res => res.json())
    .catch(err => Promise.reject(err));

export const fetchSingleOrder = (token, uuid) => {
  const url = `${SHOP_ORDERS_EP}/${uuid}`;
  return requestMonimalzApi(url, null, 'GET', token)
    .then(res => res.json())
    .catch(() => Promise.reject());
};
