import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import FooterComponent from './footer';

const Footer = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { menu, footer },
      },
    }) => <FooterComponent {...props} wordings={{ ...menu, ...footer }} />}
    query={graphql`
      query FooterQuery {
        wordings {
          fr {
            menu {
              link_account
              socials_url_twitter
              socials_url_youtube
              socials_url_facebook
              socials_url_instagram
            }
            footer {
              link_cgu
              link_cgv
              link_confidentiality
              link_conformity
              newsletter_label
              newsletter_placeholder
              legals_line1
              legals_line2
            }
          }
        }
      }
    `}
  />
);

export { Footer };
