import {
  REQUEST_PRODUCT,
  REQUEST_PRODUCT_SUCCESS,
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_RESET,
  REQUEST_ADD_TO_CART,
  REQUEST_ADD_TO_CART_FAILURE,
  REQUEST_ADD_TO_CART_SUCCESS,
  REQUEST_REMOVE_FROM_CART,
  REQUEST_REMOVE_FROM_CART_FAILURE,
  REQUEST_REMOVE_FROM_CART_SUCCESS,
  REQUEST_UPDATE_ITEM_QUANTITY,
  REQUEST_UPDATE_ITEM_QUANTITY_FAILURE,
  REQUEST_UPDATE_ITEM_QUANTITY_SUCCESS,
  REQUEST_CREATE_ACCOUNT_FAILURE,
  REQUEST_CREATE_ACCOUNT_SUCCEEDED,
  REQUEST_CLEAR_CART_AND_ORDER,
  REQUEST_CLEAR_CART_AND_ORDER_SUCCESS,
  REGISTER_FORMS_ERRORS,
  ACCOUNT_FORMS_ERRORS,
  PROFILE_UPDATE,
  REQUEST_TOKEN,
  LOGOUT,
  TOKEN_RESET,
  TOKEN_OK,
  TOKEN_FAILED,
  START_LOADING,
  STOP_LOADING,
  SET_FROM_WEBSITE,
} from './actionTypes';

import {
  resetPasswordRequest,
  createAccountRequest,
  listProfiles,
  updateProfile,
  createProfile,
  fetchAllMonimalz,
  tryTokenRequest,
  apiStatusIsError,
  fetchShopOrders,
  fetchSingleOrder,
} from '../utils/monimalzApi';

import {
  removeProductFromCart,
  addProductToCart,
  updateItemQuantity,
} from '../utils/monimalz';

import { showError, showToast } from './appActions';
import { getFirstError } from './checkoutActions';
import { validateRegisterForm } from './accountActions';
import { checkProfile } from '../utils/formValidation';

export const requestMonimalzProducts = () => (dispatch, getState) => {
  // TODO: Improve with locals storage.
  // TODO: Check if products are already in the store
  // if (Object.keys(monimalzState.products).length) {
  //   return;
  // }
  dispatch({ type: REQUEST_PRODUCT });
  fetchAllMonimalz().then(products => {
    dispatch({ type: REQUEST_PRODUCT_SUCCESS, payload: products });
  });
};

export const requestAddToCart = (productId, quantity) => (
  dispatch,
  getState
) => {
  dispatch({ type: REQUEST_ADD_TO_CART });
  const { shop } = getState();
  const payload = addProductToCart(productId, quantity, shop);
  if (!payload) {
    return dispatch({ type: REQUEST_ADD_TO_CART_FAILURE });
  }
  dispatch({
    type: REQUEST_ADD_TO_CART_SUCCESS,
    payload,
  });
};

export const requestRemoveFromCart = productId => async (
  dispatch,
  getState
) => {
  dispatch({ type: REQUEST_REMOVE_FROM_CART });
  const { shop } = getState();
  const payload = removeProductFromCart(productId, shop);
  if (!payload) {
    return dispatch({ type: REQUEST_REMOVE_FROM_CART_FAILURE });
  }
  dispatch({
    type: REQUEST_REMOVE_FROM_CART_SUCCESS,
    payload,
  });
};

export const requestUpdateItemQuantity = (productId, quantity) => (
  dispatch,
  getState
) => {
  dispatch({ type: REQUEST_UPDATE_ITEM_QUANTITY });
  const { shop } = getState();
  const payload =
    quantity > 0
      ? updateItemQuantity(productId, quantity, shop)
      : removeProductFromCart(productId, shop);
  if (!payload) {
    return dispatch({
      type: REQUEST_UPDATE_ITEM_QUANTITY_FAILURE,
    });
  }
  dispatch({
    type: REQUEST_UPDATE_ITEM_QUANTITY_SUCCESS,
    payload: payload,
  });
};

export const clearCartAndOrderData = () => dispatch => {
  dispatch({ type: REQUEST_CLEAR_CART_AND_ORDER });
  dispatch({ type: REQUEST_CLEAR_CART_AND_ORDER_SUCCESS });
  // Todo: handle failure if there is any ?
};

export const resetPassword = (token, password, confirm) => dispatch => {
  dispatch({ type: REQUEST_RESET_PASSWORD });
  return resetPasswordRequest(token, password, confirm)
    .then(response => {
      if (response.status === 204) {
        dispatch({ type: REQUEST_RESET_PASSWORD_SUCCESS });
        return;
      }
      if (apiStatusIsError(response.status)) {
        response
          .json()
          .then(data => {
            if (data.err === 'PASSWORDS_DO_NOT_MATCH')
              dispatch(showError('passwords_not_identical'));
            else dispatch(showError('generic_api_error'));
          })
          .catch(() => {
            dispatch(showError('generic_api_error'));
          });
      }
    })
    .catch(() => {
      dispatch(showError('generic_api_error'));
    });
};

export const resetPasswordResetReducer = () => dispatch => {
  dispatch({ type: REQUEST_RESET_PASSWORD_RESET });
};

let TOKEN_TIMEOUT = 0;
const TOKEN_DURATION = 3000;

export const tryToken = (token, dontLogout = false) => dispatch => {
  dispatch({ type: REQUEST_TOKEN });
  return tryTokenRequest(token)
    .then(response => {
      if (response.status !== 200) {
        dispatch({ type: TOKEN_FAILED });

        if (TOKEN_TIMEOUT) {
          window.clearTimeout(TOKEN_TIMEOUT);
        }
        TOKEN_TIMEOUT = window.setTimeout(() => {
          dispatch({ type: TOKEN_RESET });
        }, TOKEN_DURATION);

        if (!dontLogout) {
          return dispatch({ type: LOGOUT });
        }
      }
      dispatch({ type: TOKEN_OK });
    })
    .catch(err => {
      dispatch(showError(err));
    });
};

export const createAccount = (form, cgv = false) => dispatch => {
  const formErrors = validateRegisterForm(form, cgv);
  dispatch({ type: START_LOADING });

  if (formErrors.errors) {
    dispatch({ type: REGISTER_FORMS_ERRORS, payload: formErrors });
    dispatch(
      showError(
        getFirstError([
          formErrors.emailErrors,
          formErrors.passwordErrors,
          formErrors.cguErrors,
        ])
      )
    );
    dispatch({ type: REQUEST_CREATE_ACCOUNT_FAILURE });
    return;
  }
  createAccountRequest(form)
    .then(response => {
      if (response.status < 300) {
        response.json().then(() => {
          dispatch({ type: SET_FROM_WEBSITE, payload: { fromWebsite: true } });
          dispatch({
            type: REQUEST_CREATE_ACCOUNT_SUCCEEDED,
            payload: { email: form.email },
          });
        });
      } else {
        response.json().then(err => {
          dispatch({ type: REQUEST_CREATE_ACCOUNT_FAILURE });
          dispatch(showError(err.err.toLowerCase()));
        });
      }
    })
    .catch(err => {
      console.error(err);
    });
};

export const updateParentProfile = profileForm => async (
  dispatch,
  getState
) => {
  try {
    const {
      account: { access_token },
    } = getState();
    const formErrors = checkProfile(profileForm);
    if (Object.keys(formErrors).length > 0) {
      dispatch({
        type: ACCOUNT_FORMS_ERRORS,
        payload: { profileErrors: formErrors },
      });
      dispatch(showError(getFirstError([formErrors])));
      return;
    } else {
      dispatch({
        type: ACCOUNT_FORMS_ERRORS,
        payload: {
          profileErrors: {},
        },
      });
    }
    // TODO: CHECK IF THE PARENT PROFILE EXIST AND UPDATE OR CREATE IT
    const profilesResponse = await listProfiles(access_token);
    const profilesData = await profilesResponse.json();
    if (profilesResponse.status === 200) {
      const parentProfile = profilesData.filter(
        profile => profile.type === 'PARENT'
      );

      const newProfileResponse =
        parentProfile && parentProfile.length
          ? await updateProfile(
              access_token,
              profileForm,
              parentProfile[0].uuid
            )
          : await createProfile(access_token, profileForm);
      const newProfileData = await newProfileResponse.json();
      dispatch({ type: PROFILE_UPDATE, payload: newProfileData });
      dispatch(showToast('profile_updated'));
    }
  } catch (e) {
    console.log('error on createParentProfile: ', { e });
    // dispatch(showError())
  }
};

export const getShopOrders = token => dispatch => {
  dispatch({ type: START_LOADING });
  return fetchShopOrders(token)
    .then(data => {
      if (data.err) {
        dispatch(showError('generic_api_error'));
        return false;
      }
      dispatch({ type: STOP_LOADING });
      return data;
    })
    .catch(() => {
      dispatch(showError('generic_api_error'));
      return false;
    });
};

export const getSingleOrder = (token, uuid) => dispatch => {
  dispatch({ type: START_LOADING });
  return fetchSingleOrder(token, uuid)
    .then(data => {
      if (data.err) {
        dispatch(showError('generic_api_error'));
        return false;
      }
      dispatch({ type: STOP_LOADING });
      return data;
    })
    .catch(() => {
      dispatch(showError('generic_api_error'));
      return false;
    });
};
