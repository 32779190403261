import cloneDeep from 'lodash/cloneDeep';
import {
  ACCOUNT_REQUEST_LOGIN,
  LOGOUT,
  PROFILE_UPDATE,
  START_LOADING,
  RESET_EMAIL_VALIDATION,
  SET_FROM_WEBSITE,
} from './actionTypes';
import { showError, showToast } from './appActions';
import {
  loginRequest,
  listProfiles,
  createProfile,
  updateProfile,
  forgotPasswordRequest,
} from '../utils/monimalzApi';
import { getFormattedAddressesForProfile } from '../utils/address';
import { checkCGU, checkEmail, checkPassword } from '../utils/formValidation';

export const handleLogin = (email, password, isHidden = '') => dispatch => {
  if (isHidden === '') {
    dispatch({ type: START_LOADING });
  }
  loginRequest(email, password)
    .then(async res => {
      res.email = email;
      const profilesResponse = await listProfiles(res.access_token);
      const profilesData = await profilesResponse.json();
      if (profilesResponse.status === 200) {
        const parentProfile = profilesData.filter(
          profile => profile.type === 'PARENT'
        );
        dispatch({ type: PROFILE_UPDATE, payload: parentProfile[0] });
      }
      if (isHidden !== '') {
        dispatch(showToast(isHidden));
      }
      return dispatch({ type: ACCOUNT_REQUEST_LOGIN, payload: res });
    })
    .catch(async errorRejected => {
      const { err } = await errorRejected.json();
      let errMsg;
      if (err.error) {
        errMsg = err.error.toLowerCase();
      } else {
        errMsg = err.toLowerCase();
      }
      if (isHidden === '') {
        return dispatch(showError(errMsg));
      }
    });
};

export const resetEmailValidation = () => dispatch =>
  dispatch({ type: RESET_EMAIL_VALIDATION });

export const handleLogout = () => dispatch => {
  return dispatch({ type: LOGOUT });
};

export const forgotPassword = email => dispatch => {
  dispatch({ type: START_LOADING });
  forgotPasswordRequest(email)
    .then(res => {
      if (res.status === 400) {
        return dispatch(showError('email_doesnt_exist'));
      }
      dispatch({ type: SET_FROM_WEBSITE, payload: { fromWebsite: true } });
      return dispatch(showToast('forgot_password'));
    })
    .catch(e => {
      console.log('Error on forgotPassword => ', e);
      return dispatch(showError('generic_api_error'));
    });
};

export const validateRegisterForm = ({ email, password }, cgu) => {
  const emailErrors = checkEmail(email); // Check email
  const passwordErrors = checkPassword(password); // Check password
  const cguErrors = checkCGU(cgu); // Check cgu

  const errors = Boolean(
    Object.keys(emailErrors).length +
      Object.keys(passwordErrors).length +
      Object.keys(cguErrors).length
  );

  return {
    emailErrors,
    passwordErrors,
    cguErrors,
    errors,
  };
};

export const updateProfileAction = () => async (dispatch, getState) => {
  try {
    const {
      checkoutForm,
      account: { access_token },
    } = getState();
    const form = cloneDeep(checkoutForm);
    const { home_address, billing } = getFormattedAddressesForProfile(
      checkoutForm
    );
    // set home_address & billing_address
    form.profile.home_address = home_address;
    form.profile.billing_address = billing;
    // set Birthdate
    if (form.profile.birthdate.length > 2) {
      const date = new Date(form.profile.birthdate.join('/'));
      form.profile.birthdate = date.getTime();
    }
    const profilesResponse = await listProfiles(access_token);
    const profilesData = await profilesResponse.json();
    if (profilesResponse.status === 200) {
      const parentProfile = profilesData.filter(
        profile => profile.type === 'PARENT'
      );
      const newProfileResponse =
        parentProfile && parentProfile.length
          ? await updateProfile(
              access_token,
              form.profile,
              parentProfile[0].uuid
            )
          : await createProfile(access_token, form.profile);
      const newProfileData = await newProfileResponse.json();
      const newBirthdate = new Date(newProfileData.birthdate * 1000);
      newProfileData.birthdate = [
        newBirthdate.getFullYear(),
        newBirthdate.getMonth() + 1,
        newBirthdate.getDate(),
      ];
      dispatch({ type: PROFILE_UPDATE, payload: newProfileData });
      return newProfileData;
    }
  } catch (e) {
    console.log('error on updateProfileAction => ', { e });
    throw { error: e };
  }
};
