import React from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import { handleLogout } from '../../../actions/accountActions';
import { openMenu, closeMenu, openBasket } from '../../../actions/appActions';

import NavBarComponent from './navBar';

const NavBar = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { menu },
      },
    }) => (
      <NavBarComponent
        productLinkLabel={menu.link_order}
        contactLinkLabel={menu.link_contact}
        loginLinkLabel={menu.link_login}
        logOutLinkLabel={menu.link_logout}
        {...props}
        onBurgerClick={() => {
          props.menuIsOpen ? props.closeMenu() : props.openMenu();
        }}
        onBasketClick={() => {
          props.openBasket();
        }}
      />
    )}
    query={graphql`
      query NavQuery {
        wordings {
          fr {
            menu {
              link_contact
              link_order
              link_logout
              link_login
            }
          }
        }
      }
    `}
  />
);

const mapStateToProps = ({
  shop: {
    cart: { nbItems },
  },
  app: { menuIsOpen },
  account: { access_token },
}) => ({
  nbItems,
  menuIsOpen,
  access_token,
});

const connectedNavBar = connect(
  mapStateToProps,
  { openMenu, closeMenu, openBasket, handleLogout }
)(NavBar);

export { connectedNavBar as NavBar };
