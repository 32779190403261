/* eslint-disable import/prefer-default-export,react/destructuring-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { resetNavState, scrollTo } from '../../../actions/appActions';

const mapStateToProps = ({ app: { isMobile } }) => ({ isMobile });

export const pageWithNavigation = WrappedComponent =>
  connect(
    mapStateToProps,
    { resetNavState, scrollTo }
  )(
    class extends Component {
      componentDidMount() {
        this.props.resetNavState();
        this.props.scrollTo();
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }
  );
