import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ArrowImage from '../../../assets/img/ui/arrow.png';

class MailChimpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocus: false,
    };
  }

  render() {
    const { label, placeholder } = this.props;
    const { isFocus } = this.state;
    const footerClassName = classNames('input-text', { focus: isFocus });

    return (
      <form
        action="https://yellowvision.us12.list-manage.com/subscribe/post?u=7c9dc4596d671beca40c3ad2a&amp;id=94d8ad5839"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <p>{label}</p>
        <div className={footerClassName}>
          <label htmlFor="mce-EMAIL">{placeholder}</label>
          <input
            type="email"
            name="EMAIL"
            className="email"
            id="mce-EMAIL"
            required
            onBlur={() => this.setState({ isFocus: false })}
            onFocus={() => this.setState({ isFocus: true })}
          />
          <div className="clear">
            <input type="image" src={ArrowImage} name="subscribe" />
          </div>
        </div>

        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
        <div
          style={{ position: 'absolute', left: '-5000px' }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_7c9dc4596d671beca40c3ad2a_94d8ad5839"
            tabIndex="-1"
          />
        </div>
      </form>
    );
  }
}

MailChimpForm.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default MailChimpForm;
