const COMPONENT_LIST = {};

let animationFrame = null;

function generateRandomId() {
  // Number between [1: 10000]
  let result = Math.floor(Math.random() * 10000 + 1);

  // Ensure unicity
  while (COMPONENT_LIST[result]) {
    result = Math.floor(Math.random() * 10000 + 1);
  }
  return result;
}

function tick() {
  Object.keys(COMPONENT_LIST).forEach(key => {
    COMPONENT_LIST[key].tick();
  });
  animationFrame = requestAnimationFrame(tick);
}

const subscribe = component => {
  const id = generateRandomId();
  COMPONENT_LIST[id] = component;

  if (!animationFrame) {
    animationFrame = requestAnimationFrame(tick);
  }
};

const unSuscribe = componentId => {
  if (COMPONENT_LIST[componentId]) {
    delete COMPONENT_LIST[componentId];
  }
};

export { subscribe, unSuscribe };
