import React, { Component } from 'react';
import PropTypes from 'prop-types';

class VideoComponent extends Component {
  constructor(props) {
    super(props);
    this.videoRef = null;
  }

  componentDidUpdate(prevProps) {
    const { isVisible, autoPlay, isPlaying } = this.props;

    if (!this.videoRef) {
      return;
    }

    // Visibility change
    if (prevProps.isVisbile !== isVisible) {
      if (!isVisible) {
        // Video hidden
        // TODO: implement more controls
        this.videoRef.pause();
      } else if (autoPlay || isPlaying) {
        this.videoRef.play();
      }
    }

    // Is playing
    if (prevProps.isPlaying !== isPlaying) {
      if (!isPlaying) {
        this.videoRef.pause();
      } else if (isVisible) {
        this.videoRef.play();
      }
    }
  }

  render() {
    const {
      autoPlay,
      muted,
      loop,
      children,
      className,
      playsInline,
      background,
      defaultImg,
    } = this.props;

    return (
      <video
        style={{
          background: `transparent no-repeat url(${background})`,
          backgroundSize: '100%',
        }}
        poster={defaultImg}
        className={className}
        autoPlay={autoPlay}
        playsInline={playsInline}
        muted={muted}
        loop={loop}
        ref={elem => {
          this.videoRef = elem;
        }}
      >
        {children}
      </video>
    );
  }
}

VideoComponent.propTypes = {
  className: PropTypes.string,
  background: PropTypes.string,
  isVisible: PropTypes.bool,
  isPlaying: PropTypes.bool,
  autoPlay: PropTypes.bool,
  muted: PropTypes.bool,
  loop: PropTypes.bool,
  children: PropTypes.node,
  playsInline: PropTypes.bool,
  defaultImg: PropTypes.string,
};

VideoComponent.defaultProps = {
  className: '',
  isVisible: false,
  isPlaying: false,
  autoPlay: false,
  muted: true,
  loop: false,
  children: null,
  playsInline: false,
  background: '',
};

// eslint-disable-next-line import/prefer-default-export
export { VideoComponent };
