export const CREATE_ACCOUNT_EP = 'v1/auth/register';
export const FORGOT_PASSWORD_EP = 'v1/auth/password/forgot';
export const RESET_PASSWORD_EP = 'v1/auth/password/reset';
export const RESERVATION_EP = 'v1/reservations';
export const LOGIN_EP = 'v1/auth/login';
export const PROFILES_EP = 'v1/profiles';
export const PRODUCT_EP = 'v1/shop/items';
export const ME_EP = 'v1/me';
export const TRANSACTIONS_EP = 'v1/transactions';
export const SHOP_ORDERS_EP = 'v1/shop/orders';
