import isMobilePhone from 'validator/lib/isMobilePhone';
import isEmail from 'validator/lib/isEmail';

// export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// export const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
export const postcodeRegex = /\d{2}[ ]?\d{3}/;

const getAge = dateString => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const VERIFICATIONS = {
  // Field must not be empty && with a value with > 1
  required: value => Boolean(value && value.length),
  phone: value => isMobilePhone(value, 'any'),
  postcode: value => postcodeRegex.test(value),
  gender: value => value.toUpperCase() === 'M' || value.toUpperCase() === 'F',
  birthdate: value =>
    value.length === 3 ? getAge(value.join('/')) >= 18 : false,
};

export const ADDRESS_FORM_VERIFICATION = {
  first_name: ['required'],
  last_name: ['required'],
  postcode: ['required', 'postcode'],
  line_1: ['required'],
  city: ['required'],
  county: ['required'],
  country: ['required'],
  phone: ['required', 'phone'],
};

export const PROFILE_FORM_VERIFICATION = {
  gender: ['required'],
  firstname: ['required'],
  lastname: ['required'],
  phone_number: ['required', 'phone'],
  birthdate: ['required', 'birthdate'],
};

export const checkEmail = email => {
  const errors = [];

  if (!email || !email.length) {
    errors.push('required');
  }
  if (!isEmail(email)) {
    errors.push('email');
  }

  if (errors.length) {
    return { email: errors };
  }
  return {};
};

export const checkPassword = password => {
  const errors = [];

  if (!password || !password.length) {
    errors.push('required');
  }
  if (password.length < 6) {
    errors.push('password');
  }

  if (errors.length) {
    return { password: errors };
  }
  return {};
};

export const checkProfile = profile =>
  Object.keys(profile).reduce((acc, key) => {
    if (PROFILE_FORM_VERIFICATION[key]) {
      const errors = PROFILE_FORM_VERIFICATION[key].reduce(
        (isOk, verificationKey) => {
          if (!VERIFICATIONS[verificationKey](profile[key])) {
            isOk.push(verificationKey);
          }
          return isOk;
        },
        []
      );
      if (errors.length) {
        acc[key] = errors;
      }
    }
    return acc;
  }, {});

export const checkAddress = address =>
  Object.keys(address).reduce((acc, key) => {
    // Check if there is any verifcation on the field
    if (ADDRESS_FORM_VERIFICATION[key]) {
      // Apply all verification
      const errors = ADDRESS_FORM_VERIFICATION[key].reduce(
        (isOk, verificationKey) => {
          if (!VERIFICATIONS[verificationKey](address[key])) {
            isOk.push(verificationKey);
          }
          return isOk;
        },
        []
      );
      if (errors.length) {
        acc[key] = errors;
      }
    }
    return acc;
  }, {});

export const checkCGU = cgu => {
  if (cgu) {
    return {};
  }
  return { cgu: ['cgu_required'] };
};
