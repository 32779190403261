/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

import { ErrorDialogContainer, ErrorDialogText } from './styles';

export const ErrorDialog = ({ message, size, dismissed }) => (
  <ErrorDialogContainer width={size} dismissed={dismissed}>
    <ErrorDialogText>{message}</ErrorDialogText>
  </ErrorDialogContainer>
);

ErrorDialog.defaultProps = {
  message: '',
};

ErrorDialog.propTypes = {
  message: PropTypes.string,
  size: PropTypes.number.isRequired,
  dismissed: PropTypes.bool.isRequired,
};
