import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { subscribe, unSuscribe } from './ParallaxManager';

class ParallaxComponent extends Component {
  constructor(props) {
    super(props);

    this.parallaxId = null;
    this.element = React.createRef();
    this.root = React.createRef();
    this.bounds = null;
    this.offset = new Float32Array(4);
  }

  componentDidMount() {
    this.parallaxId = subscribe(this);

    // Create bounding box if it does not already exist
    if (!this.bounds) {
      this.bounds = this.root.current.getBoundingClientRect();
    }
  }

  componentWillUnmount() {
    if (this.parallaxId) {
      unSuscribe(this.parallaxId);
      this.parallaxId = null;
    }
  }

  tick() {
    if (this.element.current && this.root.current) {
      const { index, skewable } = this.props;
      const element = this.element.current;

      if (this.bounds && this.bounds.top > 0) {
        this.offset[1] =
          this.bounds.top -
          window.innerHeight / 2 -
          window.scrollY +
          this.root.current.offsetHeight / 2;
      }

      // TODO: Improve naming
      let tmp = this.offset[1] - this.offset[3];
      tmp *= 0.18;

      this.offset[3] += tmp;

      if (skewable) {
        // TODO: improve translation
        const skewAngle = Math.min(Math.max(-0.1 * tmp, -10), 10);
        element.style.transform = `translate(0, ${this.offset[3] *
          (0.1 * (index * 0.1))}px) skew(${skewAngle}deg, ${skewAngle}deg)`;
      } else {
        element.style.transform = `translate(0, ${this.offset[3] *
          (0.1 * (index * 0.1))}px)`;
      }
    }
  }

  render() {
    const { children, className } = this.props;

    return (
      <div className={`parallax ${className}`} ref={this.root}>
        <div className="parallax-wrapper" ref={this.element}>
          {children}
        </div>
      </div>
    );
  }
}

ParallaxComponent.defaultProps = {
  className: '',
  skewable: false,
};

ParallaxComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  index: PropTypes.number.isRequired,
  skewable: PropTypes.bool,
};

export { ParallaxComponent };
