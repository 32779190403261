import styled from 'styled-components';

import toastDialogBackground from '../../../assets/img/ui/field-toast-popin-bg.png';

const CONTAINER_PADDING_RIGHT_WIDTH_RATIO = 0.07;
const CONTAINER_PADDING_LEFT_WIDTH_RATIO = 0.24;
const CONTAINER_WIDTH_RATIO =
  1 - CONTAINER_PADDING_RIGHT_WIDTH_RATIO - CONTAINER_PADDING_LEFT_WIDTH_RATIO;
const CONTAINER_HEIGHT_RATIO = 3.19;

export const ToastDialogContainer = styled.div`
  background-image: url(${toastDialogBackground});
  background-repeat: no-repeat;
  background-size: contain;
  position fixed;
  bottom 10px;
  left 0;
  right 0;
  margin: 20px auto 0 auto;
  width: ${props => props.width * CONTAINER_WIDTH_RATIO}px;
  height: ${props => props.width / CONTAINER_HEIGHT_RATIO}px;
  padding: ${props => (props.width / CONTAINER_HEIGHT_RATIO) * 0.2}px ${props =>
  props.width * CONTAINER_PADDING_RIGHT_WIDTH_RATIO}px 0 ${props =>
  props.width * CONTAINER_PADDING_LEFT_WIDTH_RATIO}px;
  transform-origin: left bottom;
  transition: all .8s ease;
  opacity: ${props => (props.dismissed ? 0 : 1)};
  transform: rotate(${props => (props.dismissed ? '15deg' : '0deg')});
  z-index: 9999;
`;

export const ToastDialogText = styled.p`
  margin: 0;
  font-family: 'Circular Std Bold';
  font-size: 1em;
  color: white;
`;
