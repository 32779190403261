import React, { Component, Fragment } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import MailChimpForm from './mailChimpForm';

import { SOCIALS_LINKS } from '../../../constants/socials';
import { CHARTE_PATH, CGV_PATH, CGU_PATH } from '../../../constants/terms';
import './styles.styl';

import logoFooterImage from '../../../assets/img/ui/logo-footer.png';
import conformityPdf from '../../../assets/pdf/conformity_ce.pdf';

const LEGALS_LINK = [
  {
    wordingKey: 'link_confidentiality',
    href: CHARTE_PATH,
  },
  { wordingKey: 'link_cgu', href: CGU_PATH },
  { wordingKey: 'link_cgv', href: CGV_PATH },
];

export default class Footer extends Component {
  renderFormNewsletter() {
    const { wordings } = this.props;

    return (
      <MailChimpForm
        label={wordings.newsletter_label}
        placeholder={wordings.newsletter_placeholder}
      />
    );
  }

  renderAbout() {
    return (
      <div className="about">
        <a
          href="http://66origin.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logoFooterImage} alt="" />
        </a>
      </div>
    );
  }

  renderSocialLinks() {
    const { wordings } = this.props;

    return (
      <div className="socials">
        <ul>
          {SOCIALS_LINKS.map(({ urlWordingKey, key }) => (
            <li key={key} className={key}>
              <a
                href={wordings[urlWordingKey]}
                target="_blank"
                rel="noopener noreferrer"
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderLegals() {
    const { wordings } = this.props;

    return (
      <ul className="legals">
        {LEGALS_LINK.map(({ wordingKey, href }, index) => (
          <Fragment key={wordingKey}>
            {index ? <li className="mobile-hidden">&nbsp;.&nbsp;</li> : null}
            <li>
              <a href={href} target="_blank">
                {wordings[wordingKey]}
              </a>
            </li>
          </Fragment>
        ))}
        <li className="mobile-hidden">&nbsp;.&nbsp;</li>
        <li>
          <a href={conformityPdf} target="_blank" rel="noopener noreferrer">
            {wordings.link_conformity}
          </a>
        </li>
      </ul>
    );
  }

  renderCredits() {
    const { wordings } = this.props;

    return (
      <div className="credits">
        <p>{wordings.legals_line1}</p>
        <p>{wordings.legals_line2}</p>
      </div>
    );
  }

  render() {
    return (
      <footer>
        {/* {this.renderFormNewsletter()} */}
        <div className="about-socialinks-wrapper">
          {this.renderAbout()}
          {/* {this.renderSocialLinks()} */}
        </div>
        {/* {this.renderLegals()} */}
        {/* {this.renderCredits()} */}
      </footer>
    );
  }
}

Footer.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
};
