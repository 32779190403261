import React from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';

import { closeBasket } from '../../../actions/appActions';
import { requestRemoveFromCart } from '../../../actions/shopActions';

import BasketComponent from './basket';

const Basket = props => (
  <StaticQuery
    render={({
      wordings: {
        fr: { basket, success },
      },
    }) => (
      <BasketComponent
        {...props}
        wordings={{ ...basket, ...success }}
        removeItemFromCart={props.requestRemoveFromCart}
      />
    )}
    query={graphql`
      query BasketQuery {
        wordings {
          fr {
            basket {
              item_message
              empty_message
              total_label
              checkout_link
              follow_order_link
            }
            success {
              continue_shopping
            }
          }
        }
      }
    `}
  />
);

const mapStateToProps = ({ shop: { cart }, app: { basketIsOpen } }) => ({
  cart,
  basketIsOpen,
});

const connectedBasket = connect(
  mapStateToProps,
  { closeBasket, requestRemoveFromCart }
)(Basket);

export { connectedBasket as Basket };
