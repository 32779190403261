import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import logoImage from '../../../assets/img/ui/logo.svg';

import './styles.styl';

export default class NavBar extends Component {
  renderBurger() {
    const { onBurgerClick, withBurger } = this.props;

    if (!withBurger) {
      return null;
    }

    return (
      <div
        className="burger"
        onClick={onBurgerClick}
        onKeyPress={({ key }) => {
          // TODO: improve
          if (key === 'Enter') {
            onBurgerClick();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <div />
        <div />
      </div>
    );
  }

  renderLogo() {
    const { withLogo } = this.props;

    if (withLogo) {
      return (
        <Link to="/" className="logo">
          <img src={logoImage} alt="" className="logo" />
        </Link>
      );
    }
    return null;
  }

  renderNavigation() {
    const {
      contactLinkLabel,
      productLinkLabel,
      loginLinkLabel,
      logOutLinkLabel,
      onBasketClick,
      nbItems,
      basketClassName,
      withBasket,
      access_token,
      handleLogout,
    } = this.props;

    if (!withBasket) {
      return null;
    }

    // TODO : clean 'a', use button instead to avoid href
    return (
      <div className="navigation">
        <ul>
          {access_token ? (
            <li onClick={handleLogout}>
              <Link to={'/'} className="cta">
                {logOutLinkLabel}
              </Link>
            </li>
          ) : (
            <li>
              <Link to={'/login'} className="cta">
                {loginLinkLabel}
              </Link>
            </li>
          )}
          {/* <li>
            <Link to="/contact" className="cta">
              {contactLinkLabel}
            </Link>
          </li> */}
          <li>
            <Link to="/product" className="cta">
              {productLinkLabel}
            </Link>
          </li>
          <li className={`basket ${basketClassName}`} onClick={onBasketClick}>
          <p className="plus-bounce"><a>{nbItems}</a></p>
          </li>
        </ul>
      </div>
    );
  }

  render() {
    const { menuIsOpen } = this.props;

    return (
      <nav className={menuIsOpen ? 'menu-open' : ''}>
        {this.renderBurger()}
        {this.renderLogo()}
        {this.renderNavigation()}
      </nav>
    );
  }
}

NavBar.defaultProps = {
  withLogo: false,
  withBasket: true,
  withBurger: true,
  basketClassName: '',
};

NavBar.propTypes = {
  contactLinkLabel: PropTypes.string.isRequired,
  productLinkLabel: PropTypes.string.isRequired,
  onBurgerClick: PropTypes.func.isRequired,
  onBasketClick: PropTypes.func.isRequired,
  menuIsOpen: PropTypes.bool.isRequired,
  nbItems: PropTypes.number.isRequired,
  withLogo: PropTypes.bool,
  withBasket: PropTypes.bool,
  withBurger: PropTypes.bool,
  basketClassName: PropTypes.string,
};
