/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';

import { ToastDialogContainer, ToastDialogText } from './styles';

export const ToastDialog = ({ message, size, dismissed }) => (
  <ToastDialogContainer width={size} dismissed={dismissed}>
    <ToastDialogText>{message}</ToastDialogText>
  </ToastDialogContainer>
);

ToastDialog.defaultProps = {
  message: '',
};

ToastDialog.propTypes = {
  message: PropTypes.string,
  size: PropTypes.number.isRequired,
  dismissed: PropTypes.bool.isRequired,
};
