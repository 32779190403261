import React, { Component } from 'react';
import { withPrefix } from 'gatsby';
import lottie from 'lottie-web';

import './styles.styl';

class Loader extends Component {
  constructor(props) {
    super(props);

    this.loaderRef = React.createRef();
  }

  componentDidMount() {
    if (this.loaderRef.current) {
      lottie.loadAnimation({
        container: this.loaderRef.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: withPrefix('/animations/hourglass.json'),
      });
    }
  }

  render() {
    return (
      <div className="loaderContainer">
        <div className="loader" ref={this.loaderRef} />
      </div>
    );
  }
}

export { Loader };
