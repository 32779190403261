import React from 'react';
import PropTypes from 'prop-types';

// TODO: format price and quantity
const BasketItem = ({
  item: {
    product_id: id,
    name,
    value: { formatted },
    quantity,
    image: { href } = {},
  },
  onRemoveClick,
}) => (
  <div className="item">
    <button
      type="button"
      className="delete"
      onClick={() => onRemoveClick(id)}
    />
    <div className="icon">
      <img src={href} alt="" />
    </div>
    <div className="description">
      <p className="item-name">{name}</p>
      {/* <p className="item-price">{formatted}</p> */}
    </div>
    <div className="quantity">
      <p className="item-count">{`x${quantity}`}</p>
    </div>
  </div>
);

BasketItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number,
    slug: PropTypes.string,
  }).isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export default BasketItem;
