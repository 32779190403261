import {
  UPDATE_FORM_VALUE,
  SET_IS_SHIPPING_SAME_AS_BILLING,
  RESET_CHECKOUT_TRANSACTION_STATUS,
  UPDATE_TRANSACTION_STATUS,
  UPDATE_CHECKOUT_TRANSACTION_STATUS,
  RESET_TRANSACTION_STATUS,
  CHECKOUT_FORMS_ERRORS,
  CHECKOUT_RESET_FORMS_ERRORS,
} from './actionTypes';

import { showError } from './appActions';
import { updateProfileAction } from './accountActions';

import {
  createPaymentMeans,
  // deletePaymentMeans,
  createOrder,
  payOrder,
  apiStatusIsError,
  getTransaction,
} from '../utils/monimalzApi';

import { getFormattedAddresses } from '../utils/address';
import { checkProfile, checkAddress } from '../utils/formValidation';

import { SUCCEEDED, PAID, CANCELED, FAILED } from '../constants/checkout';

export const updateFormValue = (form, field, value) => ({
  type: UPDATE_FORM_VALUE,
  form,
  field,
  value,
});

export const setIsShippingSameAsBilling = value => ({
  type: SET_IS_SHIPPING_SAME_AS_BILLING,
  payload: value,
});

export const getFirstError = forms => {
  let result = '';

  for (let i = 0; i < forms.length; i += 1) {
    const keys = Object.keys(forms[i]);

    for (let j = 0; j < keys.length; j += 1) {
      if (forms[i][keys[j]] && forms[i][keys[j]].length) {
        result = forms[i][keys[j]][0];
        break;
      }
    }
    if (result) {
      break;
    }
  }
  return result;
};

export const validateCheckoutForm = ({
  profile,
  shipping,
  billing,
  isShippingSameAsBilling,
}) => {
  const profileErrors = checkProfile(profile); // Check user profile
  const shippingErrors = checkAddress(shipping); // Check shipping address
  let billingErrors = {};

  if (!isShippingSameAsBilling) {
    billingErrors = checkAddress(billing);
  }

  const errors = Boolean(
    Object.keys(profileErrors).length +
      Object.keys(shippingErrors).length +
      Object.keys(billingErrors).length
  );

  return {
    profileErrors,
    shippingErrors,
    billingErrors,
    errors,
  };
};

export const resetCheckoutTransactionStatus = () => ({
  type: RESET_CHECKOUT_TRANSACTION_STATUS,
});

export const resetTransactionStatus = () => ({
  type: RESET_TRANSACTION_STATUS,
});

export const getTransactionStatus = () => async (dispatch, getState) => {
  try {
    dispatch({ type: RESET_TRANSACTION_STATUS });
    const {
      checkoutForm: {
        checkoutTransactionStatus: { uuid },
      },
      account: { access_token },
    } = getState();
    const STATUS = [SUCCEEDED, PAID, CANCELED, FAILED];
    const transactionResponse = await getTransaction(access_token, uuid);
    const transactionData = await transactionResponse.json();
    if (apiStatusIsError(transactionResponse.status)) {
      throw { transactionResponse, transactionData };
    }
    if (STATUS.indexOf(transactionData.status) > -1) {
      return dispatch({
        type: UPDATE_TRANSACTION_STATUS,
        value: transactionData.status,
      });
    } else {
      setTimeout(async () => {
        const pollTransacResponse = await getTransaction(access_token, uuid);
        const pollTransacData = await pollTransacResponse.json();
        if (apiStatusIsError(pollTransacResponse.status)) {
          throw { pollTransacResponse, pollTransacData };
        }
        return dispatch({
          type: UPDATE_TRANSACTION_STATUS,
          value: pollTransacData.status,
        });
      }, 3000);
    }
  } catch (e) {
    console.log('error on getTransactionStatus => ', { e });
    dispatch(showError('generic_api_error'));
  }
};

export const requestCartCheckout = stripe => async (dispatch, getState) => {
  try {
    const {
      checkoutForm,
      account: { access_token },
      shop: { cart },
    } = getState();

    dispatch(resetCheckoutTransactionStatus());
    /* -- CREATE STRIPE TOKEN -- */
    const stripePaymentToken = await stripe.createToken();
    if (stripePaymentToken.error) return dispatch(showError('card_validation'));

    /* -- FORM VALIDATION -- */
    const formErrors = validateCheckoutForm(checkoutForm);
    if (formErrors.errors) {
      dispatch({ type: CHECKOUT_FORMS_ERRORS, payload: formErrors });
      return dispatch(
        showError(
          getFirstError([
            formErrors.profileErrors,
            formErrors.shippingErrors,
            formErrors.billingErrors,
          ])
        )
      );
    } else {
      dispatch({ type: CHECKOUT_RESET_FORMS_ERRORS });
    }
    /* -- UPDATE PROFILE WITH THE FORM INFO -- */
    const updatedProfile = await dispatch(updateProfileAction());
    /* -- CREATE PAYMENT MEANS -- */
    const paymentMeansResponse = await createPaymentMeans(
      updatedProfile.uuid,
      stripePaymentToken.token.id,
      access_token
    );
    const paymentMeanData = await paymentMeansResponse.json();
    if (apiStatusIsError(paymentMeansResponse.status)) {
      if (paymentMeanData.err === 'Your card has insufficient funds.') {
        return dispatch(showError('balance_insufficient'));
      } else {
        throw { paymentMeansResponse, paymentMeanData };
      }
    }
    /* -- ORDER CHECKOUT WITH CART AND ADDRESSES -- */
    const newFormattedCart = { ...cart };
    for (let index = 0; index < newFormattedCart.items.length; index++) {
      newFormattedCart.items[index].uuid =
        newFormattedCart.items[index].product_id;
    }
    const { shipping, billing } = getFormattedAddresses(checkoutForm);
    const createOrderResponse = await createOrder(
      paymentMeanData.uuid,
      updatedProfile.uuid,
      newFormattedCart.items,
      shipping,
      billing,
      access_token
    );
    const createOrderData = await createOrderResponse.json();
    if (apiStatusIsError(createOrderResponse.status)) {
      throw { createOrderResponse, createOrderData };
    }
    /* -- MAKE PAYMENT -- */
    const payOrderResponse = await payOrder(
      createOrderData.uuid,
      `${process.env.GATSBY_MONIMALZ_FRONT_URL}checkoutSuccess`,
      access_token
    );
    const payOrderData = await payOrderResponse.json();
    if (apiStatusIsError(payOrderResponse.status)) {
      return dispatch(showError('generic_payment_error'));
    }
    dispatch({
      type: UPDATE_CHECKOUT_TRANSACTION_STATUS,
      payload: payOrderData.transaction,
    });
    /* -- DELETE PAYMENT MEAN -- */
    // const deletePaymentMeansResponse = await deletePaymentMeans(
    //   updatedProfile.uuid,
    //   paymentMeanData.uuid,
    //   access_token
    // );
    // if (apiStatusIsError(deletePaymentMeansResponse.status)) {
    //   dispatch(showError('generic_api_error'));
    // }
  } catch (e) {
    console.log('error on Request Cart Checkout => ', e);
    dispatch(showError('generic_api_error'));
  }
};
