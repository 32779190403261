import { TweenMax } from 'gsap/TweenMax';

import { isMobile } from '../utils/layout';

import {
  OPEN_MENU,
  CLOSE_MENU,
  OPEN_BASKET,
  CLOSE_BASKET,
  SELECT_MONIMALZ,
  START_SCROLLING,
  STOP_SCROLLING,
  RESET_NAV_STATE,
  SHOW_ERROR,
  HIDE_ERROR,
  WELCOME_ANIMATION_END,
  HIDE_TOAST,
  SHOW_TOAST,
} from './actionTypes';

export const openMenu = () => ({ type: OPEN_MENU });

export const closeMenu = () => ({ type: CLOSE_MENU });

export const openBasket = () => ({ type: OPEN_BASKET });

export const closeBasket = () => ({ type: CLOSE_BASKET });

export const onWelcomeAnimationEnd = () => ({ type: WELCOME_ANIMATION_END });

export const selectMonimalz = monimalz => ({
  type: SELECT_MONIMALZ,
  payload: monimalz,
});

// TODO: improve naming
export const scrollTo = (to, duration = 1.5) => dispatch => {
  // TODO: Improve
  const animatedValue = {
    value: document.documentElement.scrollTop || document.body.scrollTop,
  };
  TweenMax.to(animatedValue, duration, {
    value: to,
    onStart: () => {
      dispatch({ type: START_SCROLLING });
    },
    onComplete: () => {
      dispatch({ type: STOP_SCROLLING });
    },
    onUpdate: () => {
      window.scrollTo(0, animatedValue.value);
    },
  });
};

export const resetNavState = () => ({
  type: RESET_NAV_STATE,
  payload: { isMobile: isMobile() },
});

let ERROR_TIMEOUT = 0;
const ERROR_DURATION = 3000;

export const showError = message => dispatch => {
  dispatch({ type: SHOW_ERROR, payload: message });
  if (ERROR_TIMEOUT) {
    window.clearTimeout(ERROR_TIMEOUT);
  }
  ERROR_TIMEOUT = window.setTimeout(() => {
    dispatch({ type: HIDE_ERROR });
  }, ERROR_DURATION);
};

export const showToast = message => dispatch => {
  dispatch({ type: SHOW_TOAST, payload: message });
  if (ERROR_TIMEOUT) {
    window.clearTimeout(ERROR_TIMEOUT);
  }
  ERROR_TIMEOUT = window.setTimeout(() => {
    dispatch({ type: HIDE_TOAST });
  }, ERROR_DURATION);
};
