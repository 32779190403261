import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import './styles.styl';

const ACTIVE_VALUES = ['account_infos', 'account_orders'];

const AccountNav = ({ wordings, active, children }) => {
  const infosClass = classNames('sub-menu-text', {
    selected: ACTIVE_VALUES[0] == active,
  });
  const ordersClass = classNames('sub-menu-text', {
    selected: ACTIVE_VALUES[1] == active,
  });

  return (
    <section className="profile">
      <div className="sub-menu">
        <Link className={infosClass} to={'/account/profile'}>
          {wordings.profile_link}
        </Link>
        <Link className={ordersClass} to="/account/orders">
          {wordings.orders_link}
        </Link>
      </div>
      {children}
    </section>
  );
};

AccountNav.propTypes = {
  active: PropTypes.string.isRequired,
  wordings: PropTypes.shape({}).isRequired,
};

export { AccountNav };
