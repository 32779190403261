/* eslint disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { handleLogout } from '../../../actions/accountActions';

import MenuComponent from './menu';

const Menu = ({ access_token, handleLogout, ...props }) => (
  <StaticQuery
    render={({
      wordings: {
        fr: { menu },
      },
    }) => {
      const pageLinks = [
        { href: '/', label: menu.link_home, key: 'home' },
        { href: '/contact', label: menu.link_contact, key: 'contact' },
        // { href: '/about', label: menu.link_about, key: 'about' },
        { href: '/product', label: menu.link_order, key: 'product' },
        { href: '/faq', label: menu.link_faq, key: 'faq' },
      ];
      if (access_token !== '') {
        pageLinks.push({
          href: '/account/profile',
          label: menu.link_account,
          key: 'account',
        });
        // pageLinks.push({
        //   href: '/',
        //   label: menu.link_logout,
        //   key: 'logout',
        // });
      } else {
        pageLinks.push({
          href: '/login',
          label: menu.link_login,
          key: 'login',
        });
      }
      return (
        <MenuComponent
          handleLogout={handleLogout}
          pageLinks={pageLinks}
          socialLinks={[
            { href: menu.socials_url_instagram, key: 'instagram' },
            { href: menu.socials_url_twitter, key: 'twitter' },
            { href: menu.socials_url_youtube, key: 'youtube' },
            { href: menu.socials_url_facebook, key: 'facebook' },
          ]}
          mentions={[
            menu.mentions_line1,
            menu.mentions_line2,
            menu.mentions_line3,
            menu.mentions_line4,
          ]}
          {...props}
        />
      );
    }}
    query={graphql`
      query MenuQuery {
        wordings {
          fr {
            menu {
              link_home
              link_order
              link_about
              link_shops
              link_account
              link_login
              link_contact
              link_logout
              link_faq
              socials_url_twitter
              socials_url_youtube
              socials_url_facebook
              socials_url_instagram
              mentions_line1
              mentions_line2
              mentions_line3
            }
          }
        }
      }
    `}
  />
);

Menu.defaultProps = {
  access_token: '',
  logout: () => {},
};

Menu.propTypes = {
  access_token: PropTypes.string,
  logout: PropTypes.func,
};

const mapStateToProps = ({
  app: { menuIsOpen },
  account: { access_token },
}) => ({ menuIsOpen, access_token });

// const mapDispatchToProps = ()

const connectedMenu = connect(
  mapStateToProps,
  { handleLogout }
)(Menu);

export { connectedMenu as Menu };
