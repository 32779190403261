import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import { ErrorDialog, Loader } from '../common';

import './global.styl';
import './fonts.styl';
import { ToastDialog } from '../common/toast';

const Layout = ({
  children,
  isLoading,
  isErrorDisplayed,
  isToastDisplayed,
  isLocked,
  errorMessage,
  toastMessage,
  title,
  description,
}) => (
  <StaticQuery
    query={graphql`
      query ErrorsQuery {
        wordings {
          fr {
            errors {
              required
              phone
              postcode
              birthdate
              email
              cvc
              fund
              declined
              expired
              processing
              unknown
              multi_reservation
              empty_cart
              account_unactivated
              unknown_account
              card_validation
              generic_api_error
              generic_payment_error
              balance_insufficient
              card_declined
              email_already_used
              password_is_missing
              validation_error
              password
              email_doesnt_exist
              cgv_required
              cgu_required
              passwords_not_identical
              password_already_reset
            }
            toast {
              email_validated
              forgot_password
              profile_updated
              password_reset
            }
          }
        }
      }
    `}
    render={({
      wordings: {
        fr: { errors, toast },
      },
    }) => (
      <>
        <Helmet
          title={title}
          meta={[{ name: 'description', content: description }]}
        >
          <html lang="fr" className={isLocked ? 'locked' : ''} />
        </Helmet>
        <div>
          {isLoading ? <Loader /> : null}
          {children}
          {errorMessage ? (
            <ErrorDialog
              size={400}
              message={errors[errorMessage]}
              dismissed={!isErrorDisplayed}
            />
          ) : null}
          {toastMessage !== '' && (
            <ToastDialog
              size={400}
              message={toast[toastMessage]}
              dismissed={!isToastDisplayed}
            />
          )}
        </div>
      </>
    )}
  />
);

Layout.defaultProps = {
  isLocked: false,
  children: null,
};

Layout.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  isErrorDisplayed: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  isToastDisplayed: PropTypes.bool.isRequired,
  toastMessage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const mapStateToProps = ({
  app: {
    isScrolling,
    isLoading,
    isErrorDisplayed,
    errorMessage,
    isToastDisplayed,
    toastMessage,
  },
}) => ({
  isScrolling,
  isLoading,
  isErrorDisplayed,
  errorMessage,
  isToastDisplayed,
  toastMessage,
});

export default connect(mapStateToProps)(Layout);
