const SOCIALS_FACEBOOK = {
  key: 'facebook',
  urlWordingKey: 'socials_url_facebook',
};

const SOCIALS_INSTAGRAM = {
  key: 'instagram',
  urlWordingKey: 'socials_url_instagram',
};

const SOCIALS_YOUTUBE = {
  key: 'youtube',
  urlWordingKey: 'socials_url_youtube',
};

const SOCIALS_TWITTER = {
  key: 'twitter',
  urlWordingKey: 'socials_url_twitter',
};

export const SOCIALS_LINKS = [
  SOCIALS_TWITTER,
  SOCIALS_FACEBOOK,
  SOCIALS_YOUTUBE,
  SOCIALS_INSTAGRAM,
];
