import React, { Component, Fragment } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import BasketItem from './basketItem';

import './styles.styl';

import logoBasketImage from '../../../assets/img/basket/basket-logo.jpg';

export default class Basket extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dismissed: true,
      hidden: true,
      timeOutId: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { basketIsOpen } = this.props;
    const { timeOutId } = this.state;

    if (basketIsOpen !== nextProps.basketIsOpen) {
      if (nextProps.basketIsOpen) {
        if (timeOutId) {
          window.clearTimeout(timeOutId);
        }
        this.setState({ hidden: false, timeOutId: null });
        window.setTimeout(() => {
          this.setState({
            dismissed: false,
          });
        }, 10);
      } else {
        const currTimeOutId = window.setTimeout(() => {
          this.setState({ hidden: true });
        }, 800);
        this.setState({ dismissed: true, timeOutId: currTimeOutId });
      }
    }
  }

  renderItemsSummary() {
    const {
      wordings,
      cart: { nbItems },
    } = this.props;
    // TODO : update quantity
    return (
      <p className="items-summary">
        <span>{nbItems}</span> {wordings.item_message}
      </p>
    );
  }

  renderItems() {
    const {
      cart: { items },
      removeItemFromCart,
    } = this.props;

    return (
      <div className="item-list">
        {items.map((item, index) => (
          <BasketItem
            key={index}
            item={item}
            onRemoveClick={removeItemFromCart}
          />
        ))}
      </div>
    );
  }

  renderTotal() {
    const {
      cart: {
        displayPrice: { with_tax },
      },
      wordings,
    } = this.props;

    // Just after adding an item in the cart, sometimes there is no currency in the formatted price
    return (
      <div className="total">
        <p>{wordings.total_label}</p>
        <p className="total-price">{`${with_tax.amount / 100}€`}</p>
      </div>
    );
  }

  renderCheckoutLink() {
    const { wordings } = this.props;

    return (
      <Link to="checkoutBasket" className="link-checkout">
        <span className="button">{wordings.checkout_link}</span>
      </Link>
    );
  }

  renderContinueLink() {
    const { wordings, closeBasket } = this.props;

    return (
      <a className="link-follow-order" onClick={closeBasket}>
        {wordings.continue_shopping}
      </a>
    );
  }

  renderBasketWithItems() {
    return (
      <Fragment>
        {this.renderItemsSummary()}
        {this.renderItems()}
        {this.renderTotal()}
        {this.renderCheckoutLink()}
        {this.renderContinueLink()}
      </Fragment>
    );
  }

  renderEmptyBasket() {
    const { wordings } = this.props;
    return <p className="no-items">{wordings.empty_message}</p>;
  }

  render() {
    const {
      cart: { nbItems },
      closeBasket,
    } = this.props;
    const { hidden, dismissed } = this.state;

    const className = `basket ${hidden ? 'hidden' : ''} ${
      dismissed ? 'dismissed' : ''
    }`;

    return (
      <div id="basket" className={className}>
        <div className="overlay" />
        <div className="wrapper">
          <button type="button" className="close" onClick={closeBasket} />
          <img src={logoBasketImage} alt="" className="logo" />
          {nbItems ? this.renderBasketWithItems() : this.renderEmptyBasket()}
          {/* <Link to="/" className="link-follow-order">
            {wordings.follow_order_link}
          </Link> */}
        </div>
      </div>
    );
  }
}

Basket.propTypes = {
  wordings: PropTypes.shape({}).isRequired,
  cart: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    displayPrice: PropTypes.shape({}).isRequired,
    nbItems: PropTypes.number.isRequired,
  }).isRequired,
  basketIsOpen: PropTypes.bool.isRequired,
  closeBasket: PropTypes.func.isRequired,
  removeItemFromCart: PropTypes.func.isRequired,
};
